import React from "react";

import "./index.scss";

import { Link } from "react-router-dom";

import DynamicText from "../../DynamicText";
import Button from "../../Button";

const Triptych = (props) => {
	const { title, subtitle, text, link, pathsImage = [], isReverse = false, isDark = false } = props;

	return (
		<React.Fragment>
			<div className={`triptych container${isReverse ? " reverse" : ""}${isDark ? " dark" : ""}`}>
				<div className="container__header">
					<div className="container__subtitle">{subtitle}</div>
					<div className="container__title">{title}</div>
					<div className="container__text">{text}</div>
					<div className="container__buttons">
						<Button type={`${isDark ? "primary" : "secondary"}`} text="scopri di più" url={`${link}`} />
					</div>
				</div>
				<div className="container__cards">
					<div className="cards_card">
						<img
							src={require(`../../../images/photos/${pathsImage[0]}.jpg`)}
							alt={title + " " + subtitle}
							loading="lazy"
						/>
					</div>
					<div className="cards_card">
						<img
							src={require(`../../../images/photos/${pathsImage[1]}.jpg`)}
							alt={title + " " + subtitle}
							loading="lazy"
						/>
					</div>
					<div className="cards_card">
						<img
							src={require(`../../../images/photos/${pathsImage[2]}.jpg`)}
							alt={title + " " + subtitle}
							loading="lazy"
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Triptych;
